
import * as bootstrap from 'bootstrap'
import Utils from '../../../../utils'
import DefaultController from "../../../defaultController";
import ErpProductEntity from "../entity";
import ErpProductGeneral from "./show/general";
import ErpProductErp from "./show/erp";
import ErpProductStock from "./show/stock";
import ErpProductSupplier from "./show/supplier";
export default class ErpProductShow extends DefaultController {
    async init() {
        this.entity = "erp/products"
        this.childs = {
            general: new ErpProductGeneral(this),
            erp: new ErpProductErp(this),
            stock: new ErpProductStock(this),
            supplier: new ErpProductSupplier(this)
        }
        await super.init();
    }

    protected getEntityData(elem: any) {
        return ErpProductEntity.getEntityData(elem)
    }
    bindListeners() {
        (document.querySelector("#erp_product_save") as HTMLElement).addEventListener("click", async (e) => {
            e.preventDefault();
            const parent = document.querySelector(".row[data-entity-id]");
            await Utils.showLoader();
            const data = this.getEntityData(parent)
            if (data.manufacturerName) {
                const manufacturersResult = await Utils.entity.getAll('erp/product_manufacturers')
                const manufacturers = manufacturersResult.data
                let existingManufacturer = manufacturers.filter((f: any) => {return f.name === data.manufacturerName})[0]
                if (!existingManufacturer) {
                    existingManufacturer = await Utils.entity.upsert({
                        id: null,
                        name: data.manufacturerName
                    }, 'erp/product_manufacturers')
                    existingManufacturer = existingManufacturer.data
                }
                data.manufacturerId = existingManufacturer.id
                console.log("existingManufacturer", existingManufacturer)

            }
            const r = await Utils.entity.upsert(data, this.entity)
            if (r.status === 200) {
                await this.getEntity()
                this.toastr.success(`${Utils.translate('erp.product.name')} ${Utils.translate('generic.saved')}`, `${Utils.translate('generic.success')}`)
            } else {
                const errors: any = [];
                r.data.errors.forEach((err: any) => {
                    errors.push(`${Utils.translate(`erp.product.messages.${err.code}`)}`)
                })
                this.toastr.error(`${errors.join(", ")}`, `${Utils.translate('generic.error')}`)
                await Utils.hideLoader();
            }
        });

        (document.querySelector("#erp_product_duplicate") as HTMLElement).addEventListener("click", async (e) => {
            e.preventDefault();
            const parent = document.querySelector(".row[data-entity-id]");
            await Utils.showLoader();
            const data = this.getEntityData(parent)
            if (data.manufacturerName) {
                const manufacturersResult = await Utils.entity.getAll('erp/product_manufacturers')
                const manufacturers = manufacturersResult.data
                let existingManufacturer = manufacturers.filter((f: any) => {return f.name === data.manufacturerName})[0]
                if (!existingManufacturer) {
                    existingManufacturer = await Utils.entity.upsert({
                        id: null,
                        name: data.manufacturerName
                    }, 'erp/product_manufacturers')
                    existingManufacturer = existingManufacturer.data
                }
                data.manufacturerId = existingManufacturer.id
                console.log("existingManufacturer", existingManufacturer)

            }
            await Utils.entity.upsert(data, this.entity)
            data.id = null;
            // @ts-ignore
            data.stock = 0;
            data.productNumber = `${data.productNumber}_1`
            const r = await Utils.entity.upsert(data, this.entity)
            if (r.status === 200) {
                this.toastr.success(`${Utils.translate('erp.product.name')} ${Utils.translate('generic.messages.duplicated')}`, `${Utils.translate('generic.success')}`)
                setTimeout(() => {
                    document.location.href = `/${(window as any).currentLocale}/erp/products/${r.data.id}`
                }, 1000)
            }
        });

        (document.querySelector("#erp_product_print") as HTMLButtonElement).addEventListener("click", async (e: any) => {
           e.preventDefault();
            const requestData = await Utils.erp.downloadLabel(this.data.id, "product")
            if (requestData.status === 200) {
                const pdf = requestData.data.pdf
                const arrayBuffer = Utils.base64ToArrayBuffer(pdf);
                Utils.createAndDownloadBlobFile(arrayBuffer, 'labels');
            }
        });

        (document.querySelector("#erp_product_delete") as HTMLElement).addEventListener("click", async (e) => {
            e.preventDefault();
            const parent = document.querySelector(".row[data-entity-id]");
            await Utils.showLoader();
            const data = this.getEntityData(parent)

            const r = await Utils.entity.destroy(data.id, this.entity)
            if (r.status === 200) {

                this.toastr.success(`${Utils.translate('erp.product.name')} ${Utils.translate('generic.deleted')}`, `${Utils.translate('generic.success')}`)
                setTimeout(() => {
                    window.history.back()
                }, 1000)
            }
        });
    }

    async updateEntity(data: any) {
        await super.updateEntity(data);
        if (data.category) {
            const $newOption = jQuery("<option selected='selected'></option>").val(data.category.uuid).text(data.category.name)
            jQuery("#erp_product_category_id").append($newOption).trigger('change')
        }
    }
}